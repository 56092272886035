import React from 'react';
import '../css/style.scss';
import {Link} from 'react-router-dom';
import {LanguageConsumer, strings_homepage, strings_menu} from '../index';
import {getCookie} from '../cookies';
import PlatformConfigurator from '../0_configuration/PlatformConfigurator';

const TranslatableContest = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_menu.setLanguage(language);
            return (props.language[language] = strings_menu.contest);
        }}
    </LanguageConsumer>
);

const TranslatableLearn = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_menu.setLanguage(language);
            return (props.language[language] = strings_menu.learn);
        }}
    </LanguageConsumer>
);

const TranslatableEvents = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_menu.setLanguage(language);
            return (props.language[language] = strings_menu.events);
        }}
    </LanguageConsumer>
);

const TranslatableContact = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_menu.setLanguage(language);
            return (props.language[language] = strings_menu.contacts);
        }}
    </LanguageConsumer>
);

const TranslatableFaq = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_menu.setLanguage(language);
            return (props.language[language] = strings_menu.faq);
        }}
    </LanguageConsumer>
);

class Menu extends React.Component {
    render() {
        const language = getCookie('language')
            ? getCookie('language')
            : strings_menu.getInterfaceLanguage();
        const documentationLink = `/documentation/${language.substring(0, 2)}/`;
        return (
            <nav className="dropdownmenu">
                <ul>
                    <li>
                        <Link className="nav-link" to="/contest" target="_self">
                            <TranslatableContest language={{}} />
                        </Link>
                    </li>
                    <li>
                        <Link className="nav-link" to={documentationLink} target="_self">
                            <TranslatableLearn language={{}} />
                        </Link>
                    </li>
                    {PlatformConfigurator.isQuantnet() && (
                        <li>
                            <Link className="nav-link" to="/events" target="_self">
                                <TranslatableEvents language={{}} />
                            </Link>
                        </li>
                    )}
                    <li>
                        <a
                            className="nav-link"
                            rel="noopener noreferrer"
                            href="/contacts"
                            target="_self"
                        >
                            <TranslatableContact language={{}} />
                        </a>
                    </li>
                    <li>
                        <Link className="nav-link" to="/faq" target="_self">
                            <TranslatableFaq language={{}} />
                        </Link>
                    </li>
                </ul>
            </nav>
        );
    }
}

export default Menu;
