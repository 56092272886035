import React from 'react';
import ReactDOM from 'react-dom';
import './css/style.scss';
import LocalizedStrings from 'react-localization';
import App from './router/App';
import * as serviceWorker from './serviceWorker';
import PlatformConfigurator from './0_configuration/PlatformConfigurator';

function getBaseLocalisationDir() {
    if (PlatformConfigurator.isQuantnet()) {
        return './0_configuration/localisationQuantnet';
    }
    if (PlatformConfigurator.isQuantiacs()) {
        return './0_configuration/localisationQuantiacs';
    }
    new Error('Undefined platform config');
}

const baseDir = getBaseLocalisationDir();

const text_homepage = require(`${baseDir}/homepage`);
export const strings_homepage = new LocalizedStrings(text_homepage);

const text_menu = require(`${baseDir}/menu`);
export const strings_menu = new LocalizedStrings(text_menu);

const text_career = require(`${baseDir}/career`);
export const strings_career = new LocalizedStrings(text_career);

const text_students = require(`${baseDir}/students`);
export const strings_students = new LocalizedStrings(text_students);

const text_statistics = require(`${baseDir}/statistics`);
export const strings_statistics = new LocalizedStrings(text_statistics);

const text_statistics_users = require(`${baseDir}/statisticsUsers`);
export const strings_statistics_users = new LocalizedStrings(text_statistics_users);

const text_competitions_results = require(`${baseDir}/competitionsResults`);
export const strings_competitions_results = new LocalizedStrings(text_competitions_results);

const text_interviews = require(`${baseDir}/interviews`);
export const strings_interviews = new LocalizedStrings(text_interviews);

const text_contacts = require(`${baseDir}/contacts`);
export const strings_contacts = new LocalizedStrings(text_contacts);

const text_termsOfUse = require(`${baseDir}/termsOfUse`);
export const strings_termsOfUse = new LocalizedStrings(text_termsOfUse);

const text_personalData = require(`${baseDir}/personalData`);
export const strings_personalData = new LocalizedStrings(text_personalData);

const text_cookies = require(`${baseDir}/cookies`);
export const strings_cookies = new LocalizedStrings(text_cookies);

const text_events = require(`${baseDir}/events`);
export const strings_events = new LocalizedStrings(text_events);

const text_firstBitcoinContest = require(`${baseDir}/firstBitcoinContest`);
export const strings_firstBitcoinContest = new LocalizedStrings(text_firstBitcoinContest);

const text_openpositions = require(`${baseDir}/openpositions`);
export const strings_openpositions = new LocalizedStrings(text_openpositions);

const text_learn = require(`${baseDir}/learn`);
export const strings_learn = new LocalizedStrings(text_learn);

const text_contest = require(`${baseDir}/contest`);
export const strings_contest = new LocalizedStrings(text_contest);

const text_quant = require(`${baseDir}/job_quant`);
export const strings_quant = new LocalizedStrings(text_quant);

const text_ux = require(`${baseDir}/job_ux`);
export const strings_ux = new LocalizedStrings(text_ux);

const text_pr = require(`${baseDir}/job_pr`);
export const strings_pr = new LocalizedStrings(text_pr);

const text_community = require(`${baseDir}/job_community`);
export const strings_community = new LocalizedStrings(text_community);

const text_smm = require(`${baseDir}/job_smm`);
export const strings_smm = new LocalizedStrings(text_smm);

const text_python = require(`${baseDir}/job_python`);
export const strings_python = new LocalizedStrings(text_python);

const text_java_cloud = require(`${baseDir}/job_java_cloud`);
export const strings_java_cloud = new LocalizedStrings(text_java_cloud);

const text_errorMsg = require(`${baseDir}/errorMsg`);
export const strings_errorMsg = new LocalizedStrings(text_errorMsg);

const text_notFoundMsg = require(`${baseDir}/404`);
export const strings_notFound = new LocalizedStrings(text_notFoundMsg);

const text_faq = require(`${baseDir}/faq`);
export const strings_faq = new LocalizedStrings(text_faq);

export const LanguageContext = React.createContext();
export const LanguageConsumer = LanguageContext.Consumer;

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();
