import React from 'react';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import getMoneyFormat from '../MoneyFormatHelper';
import PlatformConfigurator from '../../0_configuration/PlatformConfigurator';

class CompetitionAllItem extends React.Component {
    render() {
        const {competition} = this.props;
        const className = 'tr_competition';

        return (
            <>
                <tr className={className}>
                    <td className="td_prize_th">
                        <span>
                            {competition.prize
                                ? `${getMoneyFormat(competition.prize.toFixed(0))} `
                                : ' '}
                        </span>{' '}
                        {PlatformConfigurator.getCurrency()}
                    </td>

                    <td className="td_duration">
                        {competition.phaseDuration} {competition.phaseDurationUnit.toLowerCase()}
                    </td>

                    <td className="td_start">
                        {moment(competition.start, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                    </td>

                    <td className="td_finish">
                        {moment(competition.finish, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                    </td>

                    <td className="td_type">{competition.competitionType}</td>
                </tr>
            </>
        );
    }
}

CompetitionAllItem.propTypes = {
    competition: PropTypes.object,
};

export default CompetitionAllItem;
