import React from 'react';
import '../../css/style.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';
import {LanguageConsumer, strings_statistics} from '../../index';
import {getCookie} from '../../cookies';

class StatisticsItem extends React.Component {
    constructor() {
        super();
        this.state = {
            isChecked: false,
        };
    }

    toggleRow = () => {
        this.setState((prevState) => ({
            isChecked: !prevState.isChecked,
        }));
    };

    render() {
        const {model, rank} = this.props;
        const username = getCookie('username');
        let className = 'subTable';
        if (model.participant.userName === username && username) {
            className += ' userStrategy';
        }

        return (
            <>
                <tr className={className}>
                    <td className="field_item rank" data-entity="submission" data-field="rank">
                        <span>{rank}</span>
                    </td>
                    <td className="field_item name" data-entity="submission" data-field="name">
                        <span>{model.name}</span>
                    </td>
                    <td
                        className="field_item author"
                        data-entity="submission"
                        data-field="competitionType"
                    >
                        {model.competitionType}
                    </td>
                    <td
                        className="field_item instruments"
                        data-entity="run"
                        data-field="instruments"
                    >
                        {model.instrumentsCount}
                    </td>
                    <td className="field_item sub_duration" data-entity="run" data-field="duration">
                        {model.duration}
                    </td>
                    <td className="field_item is" data-entity="run" data-field="ScoreSharpeRatio">
                        {model.ScoreSharpeRatio.toPrecision(4)}
                    </td>
                    <td
                        className="field_item holdingTime"
                        data-entity="run"
                        data-field="holdingTime"
                    >
                        {model.holdingTime && model.holdingTime.toFixed(2)}
                    </td>
                    <td
                        className="field_item tr_duration"
                        data-entity="run"
                        data-field="tradeDuration"
                    >
                        {`${(model.avgTurnover * 100).toFixed(2)}%`}
                    </td>
                    <td className="field_item max_dd" data-entity="run" data-field="maxDropdown">
                        {`${(model.maxDropdown * 100).toFixed(2) * -1}%`}
                    </td>
                    <td className="shrink-toggle" onClick={this.toggleRow}>
                        <span>
                            {this.state.isChecked && (
                                <FontAwesomeIcon
                                    fixedWidth
                                    icon={faChevronUp}
                                    color="red"
                                    size="1x"
                                />
                            )}
                            {!this.state.isChecked && (
                                <FontAwesomeIcon
                                    fixedWidth
                                    icon={faChevronDown}
                                    color="red"
                                    size="1x"
                                />
                            )}
                        </span>
                    </td>
                </tr>
                <tr
                    className={
                        this.state.isChecked
                            ? 'shrink-wrapper m-fadeIn'
                            : 'shrink-wrapper m-fadeOut'
                    }
                >
                    <td colSpan={99}>
                        <div>
                            <table className="subTable">
                                <tbody>
                                    <tr className="shrink-author">
                                        <td>Type</td>
                                        <td>{model.competitionType}</td>
                                    </tr>
                                    <tr className="shrink-instruments">
                                        <td>Instruments</td>
                                        <td>{model.instrumentsCount}</td>
                                    </tr>
                                    <tr className="shrink-duration">
                                        <td>Days after Submission</td>
                                        <td>{model.duration}</td>
                                    </tr>
                                    <tr className="shrink-is">
                                        <td>Score Sharpe Ratio</td>
                                        <td>{model.ScoreSharpeRatio.toPrecision(4)}</td>
                                    </tr>
                                    <tr className="shrink-holdingTime">
                                        <td>Holding Time</td>
                                        <td>{model.holdingTime && model.holdingTime.toFixed(2)}</td>
                                    </tr>
                                    <tr className="shrink-avgTurnover">
                                        <td>Average Turnover</td>
                                        <td>{`${(model.avgTurnover * 100).toFixed(2)}%`}</td>
                                    </tr>
                                    <tr className="shrink-maxDropdown">
                                        <td>Maximum Drawdown</td>
                                        <td>{`${(model.maxDropdown * 100).toFixed(2) * -1}%`}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            </>
        );
    }
}

export default StatisticsItem;
