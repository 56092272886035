export const quantnet = {
    homePage: {
        siteName: 'quantiacs',
        logUrl:
            'https://www.quantiacs.com/QuantNetSite/media/BindTuning-SPCTheme/quantiacs-logo.png?width=283&height=63&ext=.png',

        contacts: {
            // Telegram: {
            //     name: 'Telegram',
            //     title: '@quantnetrussia',
            //     link: 'https://t.me/quantnetrussia',
            // },
            // GitHub: {
            //     name: 'GitHub',
            //     title: 'qntnet',
            //     link: 'https://github.com/qntnet',
            // },
            // EmailSupport: {
            //     name: 'Email',
            //     title: 'support@quantnet.ai',
            //     link: 'mailto:support@quantnet.ai',
            emailSupport: {
                name: 'Email',
                title: 'info@quantiacs.com',
                link: 'mailto:info@quantiacs.com',
            },

            facebook: {
                name: 'Facebook',
                title: 'Facebook',
                link: 'https://www.facebook.com/quantiacs/',
            },

            twitter: {
                name: 'Twitter',
                title: 'Twitter',
                link: 'https://twitter.com/Quantiacs',
            },

            linkedin: {
                name: 'Linkedin',
                title: 'Linkedin',
                link: 'https://www.linkedin.com/company/quantiacs',
            },

            youtube: {
                name: 'Youtube',
                title: 'Youtube',
                link: 'https://www.youtube.com/channel/UCS8bIV3uEJLIaA91s5uQzQw',
            },
        },
    },
    general: {
        currency: 'RUB',
        logoClassName: 'quantnet-logo',
    },

    account: {
        contacts: {
            telegram: {
                name: 'Telegram',
                title: '@quantnetrussia',
                link: 'https://t.me/quantnetrussia',
            },
            gitHub: {
                name: 'GitHub',
                title: 'qntnet',
                link: 'https://github.com/qntnet',
            },
            emailSupport: {
                name: 'Email',
                title: 'support@quantnet.ai',
                link: 'mailto:support@quantnet.ai',
            },
        },
    },
};
