import React from 'react';
import $ from 'jquery';
import {strings_contacts} from '../../../index';
import {getCookie} from '../../../cookies';
import * as tracker from '../../../tracker';

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                personaldata: false,
            },
            errors: {},
            disabled: true,
            submitMessage: '',
            success: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
    }

    handleChange(e) {
        const {fields} = this.state;
        if (e.target.name === 'personaldata') {
            fields[e.target.name] = e.target.checked;
            this.setState({
                disabled: !e.target.checked,
            });
        } else {
            fields[e.target.name] = e.target.value;
            this.setState({
                fields,
            });
        }
    }

    submituserRegistrationForm(e) {
        e.preventDefault();
        if (this.validateForm()) {
            fetch('/engine/feedback/', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: this.state.fields.username,
                    phone: 0,
                    email: this.state.fields.emailid,
                    title: this.state.fields.theme,
                    comment: this.state.fields.message,
                    attachment: '',
                }),
            })
                .then((response) => {
                    if (response.status == 200) {
                        const that = this;
                        this.setState({
                            submitMessage: this.state.localisation.formPlaceholder_success,
                            success: true,
                        });
                        setTimeout(() => {
                            const fields = {};
                            fields.username = '';
                            fields.emailid = '';
                            fields.mobileno = '';
                            fields.theme = '';
                            fields.message = '';
                            that.setState({
                                fields,
                                disabled: true,
                                success: false,
                                submitMessage: '',
                                submit: that.state.localisation.formPlaceholder_submit,
                            });
                        }, 1000);
                        tracker.event('SEND_FEEDBACK');
                    }
                })
                .catch((error) => {
                    console.error(error);
                    const fields = {};
                    fields.username = '';
                    fields.emailid = '';
                    fields.mobileno = '';
                    fields.theme = '';
                    fields.message = '';

                    this.setState({fields, disabled: true});
                });
        }
    }

    validateForm() {
        const {fields} = this.state;
        const errors = {};
        const {disabled} = this.state;
        let formIsValid = true;

        if (!fields.username) {
            formIsValid = false;
            errors.username = this.state.localisation.formErrors.username_empty;
        }
        if (typeof fields.username !== 'undefined' && fields.username !== '') {
            if (!fields.username.match(/^([- A-Za-zа-яА-ЯёЁ]+)$/)) {
                formIsValid = false;
                errors.username = this.state.localisation.formErrors.username_invalid;
            }
        }
        if (!fields.theme) {
            fields.theme = '';
        }

        if (typeof fields.theme !== 'undefined' && fields.theme !== '') {
            if (!fields.theme.match(/^([- A-Za-zа-яА-ЯёЁ]+)$/)) {
                formIsValid = false;
                errors.theme = this.state.localisation.formErrors.theme_invalid;
            }
        }

        if (!fields.message) {
            fields.message = '';
            formIsValid = false;
            errors.message = this.state.localisation.formErrors.message_empty;
        }

        if (typeof fields.message !== 'undefined' && fields.message !== '') {
            const re = /[$"<>]/g;
            if (fields.message.match(re) != null) {
                formIsValid = false;
                errors.message = this.state.localisation.formErrors.message_invalid;
            }
        }

        if (!fields.emailid) {
            formIsValid = false;
            errors.emailid = this.state.localisation.formErrors.email_empty;
        }

        if (typeof fields.emailid !== 'undefined' && fields.emailid !== '') {
            // regular expression for email validation
            const pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
            );
            if (!pattern.test(fields.emailid)) {
                formIsValid = false;
                errors.emailid = this.state.localisation.formErrors.email_invalid;
            }
        }
        this.setState({
            errors,
            fields,
        });
        return formIsValid;
    }

    componentWillMount() {
        this.setState({
            localisation: this.props.data,
            submit: this.props.data.formPlaceholder_submit,
        });
    }

    componentWillReceiveProps() {
        this.setState({
            errors: {},
        });
    }

    clickCheckbox = () => {
        const {fields} = this.state;
        const newPersonaldata = fields.personaldata;
        fields.personaldata = !newPersonaldata;
        this.setState({
            fields,
            disabled: newPersonaldata,
        });
    };

    render() {
        return (
            <div className="contactFormContainer">
                <div className="contactForm">
                    <form
                        method="post"
                        name="userRegistrationForm"
                        onSubmit={this.submituserRegistrationForm}
                    >
                        <input
                            className="ym-disable-keys"
                            placeholder={this.state.localisation.formPlaceholder_name}
                            type="text"
                            maxLength={40}
                            name="username"
                            value={this.state.fields.username}
                            onChange={this.handleChange}
                            onKeyUp={this.handleChange}
                        />
                        <div className="errorMsg">{this.state.errors.username}</div>

                        <input
                            className="ym-disable-keys"
                            placeholder={this.state.localisation.formPlaceholder_email}
                            type="text"
                            name="emailid"
                            maxLength={254}
                            value={this.state.fields.emailid}
                            onChange={this.handleChange}
                            onKeyUp={this.handleChange}
                        />
                        <div className="errorMsg">{this.state.errors.emailid}</div>

                        <input
                            className="ym-disable-keys"
                            placeholder={this.state.localisation.formPlaceholder_theme}
                            type="text"
                            maxLength={120}
                            name="theme"
                            value={this.state.fields.theme}
                            onChange={this.handleChange}
                            onKeyUp={this.handleChange}
                        />
                        <div className="errorMsg">{this.state.errors.theme}</div>

                        <textarea
                            className="ym-disable-keys"
                            rows="6"
                            placeholder={this.state.localisation.formPlaceholder_message}
                            cols="30"
                            maxLength={1500}
                            name="message"
                            value={this.state.fields.message}
                            onChange={this.handleChange}
                            onKeyUp={this.handleChange}
                        />
                        <div className="errorMsg">{this.state.errors.message}</div>

                        <div className="filter_item checkbox_div col-md-4 col-sm-4 col-xs-12">
                            <label className="checkbox_wrapper">
                                <input
                                    className="checkbox"
                                    type="checkbox"
                                    name="future"
                                    checked={this.state.fields.personaldata}
                                    onChange={this.handleChange}
                                    id="future"
                                />
                                <span
                                    style={{background: 'white'}}
                                    className="checkbox-custom trigger_filter"
                                    id="future_check"
                                    onClick={this.clickCheckbox}
                                />
                                <span className="label" onClick={this.clickCheckbox}>
                                    {this.state.localisation.formPlaceholder_personaldata}
                                </span>
                            </label>
                        </div>
                        <div
                            className={this.state.success ? 'copied success' : 'copied'}
                            data-tooltip={this.state.localisation.formPlaceholder_success}
                        >
                            <input
                                type="submit"
                                className="button"
                                value={this.state.localisation.formPlaceholder_submit}
                                disabled={this.state.disabled}
                            />
                        </div>
                    </form>
                    <div
                        className="mainInfoWrapper about_wrapper"
                        style={{
                            display: 'flex',
                            justifyContent: 'start',
                            width: 'fit-content',
                            padding: '2% 0',
                            alignItems: 'start',
                        }}
                    ></div>
                </div>
            </div>
        );
    }
}

export default ContactForm;
