import React from 'react';
import '../css/style.scss';
import {LanguageConsumer, strings_menu} from '../index';
import {getCookie} from '../cookies';
import PlatformConfigurator from '../0_configuration/PlatformConfigurator';

const TranslatableHome = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_menu.setLanguage(language);
            return (props.language[language] = strings_menu.main);
        }}
    </LanguageConsumer>
);

const TranslatableContact = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_menu.setLanguage(language);
            return (props.language[language] = strings_menu.contacts);
        }}
    </LanguageConsumer>
);
const TranslatableEvents = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_menu.setLanguage(language);
            return (props.language[language] = strings_menu.events);
        }}
    </LanguageConsumer>
);
const TranslatableContest = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_menu.setLanguage(language);
            return (props.language[language] = strings_menu.contest);
        }}
    </LanguageConsumer>
);
const TranslatableLearn = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_menu.setLanguage(language);
            return (props.language[language] = strings_menu.learn);
        }}
    </LanguageConsumer>
);

const TranslatableFaq = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_menu.setLanguage(language);
            return (props.language[language] = strings_menu.faq);
        }}
    </LanguageConsumer>
);

class MobileMenu extends React.Component {
    render() {
        const language = getCookie('language')
            ? getCookie('language')
            : strings_menu.getInterfaceLanguage();
        const documentationLink = `/documentation/${language.substring(0, 2)}/`;
        return (
            <div className="headerWrapper">
                <div id="wrapper">
                    <label
                        id="nav-icon3"
                        className="menu-icon"
                        htmlFor="toggle"
                        onClick={(e) => e.target.closest('#nav-icon3').classList.toggle('open')}
                    >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </label>
                    <input type="checkbox" id="toggle" />
                    <nav>
                        <ul>
                            <li>
                                <a className="nav-link" href="/" target="_self">
                                    <TranslatableHome language={{}} />
                                </a>
                            </li>
                            <li>
                                <a className="nav-link" href="/contest" target="_self">
                                    <TranslatableContest language={{}} />
                                </a>
                            </li>
                            <li>
                                <a className="nav-link" href={documentationLink} target="_self">
                                    <TranslatableLearn language={{}} />
                                </a>
                            </li>
                            {PlatformConfigurator.isQuantnet() && (
                                <li>
                                    <a className="nav-link" href="/events" target="_self">
                                        <TranslatableEvents language={{}} />
                                    </a>
                                </li>
                            )}

                            <li>
                                <a
                                    className="nav-link"
                                    rel="noopener noreferrer"
                                    href="/contacts"
                                    target="_self"
                                >
                                    <TranslatableContact language={{}} />
                                </a>
                            </li>
                            <li>
                                <a className="nav-link" href="/faq" target="_self">
                                    <TranslatableFaq language={{}} />
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        );
    }
}

export default MobileMenu;
