import React from 'react';

import * as PropTypes from 'prop-types';
import getMoneyFormat from '../MoneyFormatHelper';

import {LanguageConsumer, strings_statistics_users} from '../../index';

class TopRankUsersTable extends React.Component {
    state = {
        activeColumn: 'paidPrize',
        sortedAscending: true,
    };

    handleSortBy(columnForSort) {
        const {sortedAscending} = this.state;
        if (columnForSort === this.state.activeColumn) {
            this.setState({
                sortedAscending: !sortedAscending,
            });
            return;
        }
        this.setState({
            activeColumn: columnForSort,
            sortedAscending: false,
        });
    }

    getSortByColumn(userStatistic, columnName, isReverse) {
        const statisticSorted = [...userStatistic];
        switch (columnName) {
            case 'estimatedPrize':
                if (isReverse) {
                    statisticSorted.sort(sortNumeric).reverse();
                } else {
                    statisticSorted.sort(sortNumeric);
                }
                break;
            case 'paidPrize':
                if (isReverse) {
                    statisticSorted.sort(sortNumeric).reverse();
                } else {
                    statisticSorted.sort(sortNumeric);
                }
                break;
            case 'username':
                if (isReverse) {
                    statisticSorted.sort(sortString).reverse();
                } else {
                    statisticSorted.sort(sortString);
                }
                break;
            case 'runningStrategies':
                if (isReverse) {
                    statisticSorted.sort(sortNumeric).reverse();
                } else {
                    statisticSorted.sort(sortNumeric);
                }
                break;

            default:
                throw new Error('you are sorting by unknown property');
        }

        return statisticSorted;

        function sortNumeric(a, b) {
            return a[columnName] - b[columnName];
        }

        function sortString(a, b) {
            return `${a[columnName]}`.localeCompare(b[columnName]);
        }
    }

    render() {
        return (
            <LanguageConsumer>
                {({language}) => {
                    strings_statistics_users.setLanguage(language);
                    return this.renderLanguage(strings_statistics_users);
                }}
            </LanguageConsumer>
        );
    }

    renderLanguage(messages) {
        const statisticAll = this.getSortByColumn(
            this.props.usersStatistic,
            this.state.activeColumn,
            this.state.sortedAscending,
        );
        const statistic = statisticAll.slice(0, 5);

        return (
            <div>
                <section className="global_list">
                    <table className="topRankUsersTable">
                        <thead>
                            <tr>
                                <th className="field_item">№</th>
                                <th
                                    className="field_item"
                                    onClick={() => this.handleSortBy('username')}
                                >
                                    {messages.username}
                                </th>
                                <th
                                    className="field_item"
                                    onClick={() => this.handleSortBy('paidPrize')}
                                >
                                    {messages.paid_prize}
                                </th>
                                <th
                                    className="field_item"
                                    onClick={() => this.handleSortBy('estimatedPrize')}
                                >
                                    {messages.estimated_prize}
                                </th>
                                <th
                                    className="field_item"
                                    onClick={() => this.handleSortBy('runningStrategies')}
                                >
                                    {messages.number_active_strategies}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {statistic.map((user, key) => (
                                <tr key={user.username}>
                                    <td className="field_item">{key + 1}</td>
                                    <td className="field_item">{user.username}</td>
                                    <td className="field_item">
                                        {getMoneyFormat(user.paidPrize.toFixed(0))}
                                    </td>
                                    <td className="field_item">
                                        {getMoneyFormat(user.estimatedPrize.toFixed(0))}
                                    </td>
                                    <td className="field_item">{user.runningStrategies}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </section>
            </div>
        );
    }
}

TopRankUsersTable.propTypes = {
    usersStatistic: PropTypes.array,
};

export default TopRankUsersTable;
