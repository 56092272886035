import React from 'react';
import AdditionalInfo from './forms/AdditionalInfo';
import {LanguageConsumer, strings_contacts} from '../../index';

const TranslatableTitle = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_contacts.setLanguage(language);
            return (props.language[language] = strings_contacts.mainTitle2);
        }}
    </LanguageConsumer>
);

class Contacts extends React.Component {
    render() {
        return (
            <div className="wrapper_contacts">
                <div className="contacts_wrapper contacts_wrapper_main">
                    <h2 className="section_header_contacts">
                        <TranslatableTitle language={{}} />
                    </h2>
                    <AdditionalInfo />
                </div>
            </div>
        );
    }
}

export default Contacts;
