import React from 'react';

import TopRankUsersAboutPrize from './TopRankUsersAboutPrize';

import TopRankUsersTable from './TopRankUsersTable';
import {LanguageConsumer, strings_statistics_users} from '../../index';
import * as tracker from '../../tracker';

class GlobalRankUsers extends React.Component {
    constructor() {
        super();
        this.state = {
            usersStatistic: [
                {
                    username: 'raider252',
                    paidPrize: 2939800.7,
                    estimatedPrize: 0,
                    runningStrategies: 0,
                    completedStrategies: 701,
                },
                {
                    username: 'kvanvanvant_test_python',
                    paidPrize: 1330000.1,
                    estimatedPrize: 0,
                    runningStrategies: 0,
                    completedStrategies: 342,
                },
                {
                    username: 'lookman',
                    paidPrize: 1116552.8,
                    estimatedPrize: 0,
                    runningStrategies: 0,
                    completedStrategies: 565,
                },
                {
                    username: 'Panych',
                    paidPrize: 563021.77,
                    estimatedPrize: 0,
                    runningStrategies: 0,
                    completedStrategies: 235,
                },
                {
                    username: 'Difenischin',
                    paidPrize: 431572.32,
                    estimatedPrize: 0,
                    runningStrategies: 0,
                    completedStrategies: 107,
                },
            ],
            totalPrizeFondActive: 0,
            totalPrizeFondPaid: 7766045,
        };
    }

    componentDidMount() {
        this.fetchCompetitions();
    }

    fetchCompetitions = async () => {
        try {
            const usersStatistic = await getUsersStatistic();

            this.setState({
                usersStatistic: usersStatistic.participants,
                totalPrizeFondActive: usersStatistic.activePrize,
                totalPrizeFondPaid: usersStatistic.paidPrize,
            });
        } catch (e) {
            console.error(e);
        }

        async function getUsersStatistic() {
            const response = await fetch(`/referee/competitionStat/participants/`, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                },
                cache: 'default',
            });
            return response.json();
        }
    };

    render() {
        return (
            <LanguageConsumer>
                {({language}) => {
                    strings_statistics_users.setLanguage(language);
                    return this.renderLanguage(strings_statistics_users);
                }}
            </LanguageConsumer>
        );
    }

    renderLanguage(messages) {
        return (
            <div className="topRankUsers">
                <TopRankUsersAboutPrize
                    totalPrizeFondActive={this.state.totalPrizeFondActive}
                    totalPrizeFondPaid={this.state.totalPrizeFondPaid}
                />

                <div className="">
                    <h2 className="topTenHeader">{messages.TopUserTitle}</h2>

                    <div className="globalRankWrapper">
                        <TopRankUsersTable usersStatistic={this.state.usersStatistic} />
                    </div>
                    <div className="topRankUsersButtonWrapper">
                        <a
                            className="rankUsersLeaderboardLink"
                            href="/personalpage/statistics"
                            onClick={() => this.collectStatistics()}
                        >
                            {messages.TopUserLinkTitle}
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    collectStatistics = () => {
        tracker.event('BUTTON_LEADERBOARD_USERS');
    };
}

export default GlobalRankUsers;
