import React from 'react';
import '../css/style.scss';
import Slogan from './slogan/Slogan';
import Proposals from './proposals/Proposals';
import Uniqueness from './uniqueness/Uniqueness';
import Statistics from './statistics/Statistics';
import GlobalRankUsers from './statisticsUser/index';
import Contacts from './contacts/Contacts';
import CompetitionsResults from './competitionsResults';
import AboutLibrary from './aboutLibrary/AboutLibrary';
import * as tracker from '../tracker';

class Homepage extends React.Component {
    render() {
        return (
            <>
                <Slogan />
                <GlobalRankUsers />
                <Proposals />
                <AboutLibrary />
                <CompetitionsResults />
                <Uniqueness />
                <Statistics />
                <Contacts />
            </>
        );
    }
}

export default Homepage;
