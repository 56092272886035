import React from 'react';
import '../../css/style.scss';
import {LanguageConsumer, strings_homepage} from '../../index';
import * as tracker from '../../tracker';

class Slogan extends React.Component {
    collectStatistics = () => {
        tracker.event('MAIN_BUTTON_PRESSED');
    };

    render() {
        return (
            <div className="body_intro_wrapper">
                <div className="body_intro">
                    <div className="body_intro_slogan">
                        <LanguageConsumer>
                            {({language}) => {
                                strings_homepage.setLanguage(language);
                                if (language === 'ru') {
                                    return this.renderRu();
                                }

                                return this.renderEn();
                            }}
                        </LanguageConsumer>
                    </div>
                </div>
            </div>
        );
    }

    renderRu() {
        return (
            <div className="body_intro_container">
                <h2> Создай алгоритм и выиграй деньги.</h2>

                <p>
                    <strong>QuantNet</strong> - это платформа для соревнований в области Fintech и
                    Data Science.
                </p>

                {/* <p> */}
                {/*     Каждый квартал мы разыгрываем <strong>500 тысяч рублей.</strong> */}
                {/* </p> */}
                <p>
                    <a
                        className="startLink button_example"
                        rel="noopener noreferrer"
                        href={`${window.location.origin.toString()}/contest`}
                        target="_self"
                        onClick={() => this.collectStatistics()}
                    >
                        Узнать больше
                    </a>
                </p>
            </div>
        );
    }

    renderEn() {
        return (
            <div className="body_intro_container">
                <h2>Create an algorithm and win money.</h2>

                <p>
                    <strong>QuantNet</strong> is a Fintech and Data Science competition platform.
                </p>

                {/* <p> */}
                {/*     Every quarter we are giving away. <strong>500 thousand rubles.</strong> */}
                {/* </p> */}

                <p>
                    <a
                        className="startLink button_example"
                        rel="noopener noreferrer"
                        href={`${window.location.origin.toString()}/contest`}
                        target="_self"
                        onClick={() => this.collectStatistics()}
                    >
                        More information
                    </a>
                </p>
            </div>
        );
    }
}

export default Slogan;
