import React from 'react';
import moment from 'moment';
import {getCookie} from '../../cookies';

import LoadingIndicator from '../../LoadingIndicator';
import * as tracker from '../../tracker';
import CompetitionAll from './CompetitionAll';

import {LanguageConsumer, strings_competitions_results} from '../../index';

class CompetitionsResults extends React.Component {
    constructor() {
        super();

        this.state = {
            competitionsRunning: [],
            competitionsCompleted: [],
        };
    }

    componentDidMount() {
        this.fetchCompetitions();
    }

    fetchCompetitions = async () => {
        try {
            const competitionsRunning = await getCompetitions('running');
            if (competitionsRunning.length) {
                this.setState({
                    competitionsRunning,
                });
            }
        } catch (e) {
            console.error(e);
        }
        try {
            const competitionsCompleted = await getCompetitions('completed');
            if (competitionsCompleted.length) {
                this.setState({
                    competitionsCompleted,
                });
            }
        } catch (e) {
            console.error(e);
        }

        async function getCompetitions(name = '') {
            const response = await fetch(`/referee/competitionStat/${name}?skipResult=true`, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                },
                cache: 'default',
            });
            if (response.status !== 200 || !response.ok) {
                throw new Error(response.statusText);
            }

            const json = await response.json();
            return json;
        }
    };

    addDatesEndCompetitions(competitions, lang = 'en') {
        const result = competitions.map((item) => {
            item.finish = moment(item.start)
                .add(item.phaseDuration, String(item.phaseDurationUnit).toLowerCase())
                .format('YYYY-MM-DD');
            const finishMonthYear = moment(item.start)
                .add(item.phaseDuration, String(item.phaseDurationUnit).toLowerCase())
                .locale(lang);
            item.finishMonthYear = finishMonthYear.format('MMMM YYYY');
            return item;
        });
        return result;
    }

    collectStatistics = () => {
        tracker.event('GO_TO_ACCOUNT_STATISTICS');
    };

    loading() {
        return <LoadingIndicator />;
    }

    render() {
        return (
            <LanguageConsumer>
                {({language}) => {
                    strings_competitions_results.setLanguage(language);
                    const competitionsRunning = this.addDatesEndCompetitions(
                        this.state.competitionsRunning,
                        language,
                    );
                    const competitionsCompleted = this.addDatesEndCompetitions(
                        this.state.competitionsCompleted,
                        language,
                    );
                    return this.renderLanguage(
                        strings_competitions_results,
                        competitionsRunning,
                        competitionsCompleted,
                    );
                }}
            </LanguageConsumer>
        );
    }

    renderLanguage(messages, competitionsRunning, competitionsCompleted) {
        return (
            <>
                <section>
                    <div className="competitions_results">
                        <h2>{messages.competitionCompletedTitle}</h2>
                        <CompetitionAll
                            competitions={competitionsCompleted}
                            isDirectionSortDown={false}
                            messages={messages}
                        />
                        <h2>{messages.competitionRunningTitle}</h2>
                        <section>
                            <CompetitionAll
                                competitions={competitionsRunning}
                                isDirectionSortDown
                                messages={messages}
                            />
                        </section>
                        <div className="competitions_results_button_wrapper">
                            <a
                                className="leaderbord_link"
                                href="/personalpage/statistics"
                                onClick={() => this.collectStatistics()}
                            >
                                {messages.show_all_statistic}
                            </a>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default CompetitionsResults;
