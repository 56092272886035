import React from 'react';
import '../../css/style.scss';
import {Link} from 'react-router-dom';
import {LanguageConsumer, strings_statistics} from '../../index';
import StatisticsItem from './StatisticsItem';
import {getCookie, setCookie} from '../../cookies';
import * as tracker from '../../tracker';

const TranslatableTitle = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_statistics.setLanguage(language);
            return (props.language[language] = strings_statistics.globalStatistisTitle);
        }}
    </LanguageConsumer>
);
const TranslatableDescr = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_statistics.setLanguage(language);

            return (
                <p className="globalStatistisText">
                    <span>{strings_statistics.globalStatistisText}</span>
                    <span>{' 3 '}</span>
                    <span>{strings_statistics.globalStatistisUnit}</span>
                </p>
            );
        }}
    </LanguageConsumer>
);
const TranslatableLink = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_statistics.setLanguage(language);
            return (props.language[language] = strings_statistics.globalLinkTitle);
        }}
    </LanguageConsumer>
);
const TranslatableHeader = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_statistics.setLanguage(language);
            props.language[language] = strings_statistics;
            return (
                <tr>
                    <th
                        className="field_item field_item_title rank"
                        data-entity="submission"
                        data-field="rank"
                    >
                        <span>{strings_statistics.strategyRank}</span>
                    </th>
                    <th
                        className="field_item field_item_title name"
                        data-entity="submission"
                        data-field="name"
                    >
                        <span>{strings_statistics.strategyTitle}</span>
                    </th>
                    <th
                        className="field_item field_item_title author"
                        data-entity="submission"
                        data-field="competitionType"
                    >
                        <span>{strings_statistics.strategyType}</span>
                    </th>
                    <th
                        className="field_item field_item_title instruments"
                        title={strings_statistics.instruments}
                        data-entity="run"
                        data-field="instruments"
                    >
                        <span>Instruments</span>
                    </th>
                    <th
                        className="field_item field_item_title sub_duration"
                        title={strings_statistics.daysAfterSubmission}
                        data-entity="run"
                        data-field="duration"
                    >
                        Days after Submission
                    </th>
                    <th
                        className="field_item field_item_title is"
                        title={strings_statistics.ScoreSharpeRatio}
                        data-entity="run"
                        data-field="ScoreSharpeRatio"
                    >
                        Score Sharpe Ratio
                    </th>
                    <th
                        className="field_item field_item_title holdingTime"
                        title={strings_statistics.holdingTime}
                        data-entity="run"
                        data-field="holdingTime"
                    >
                        Holding Time
                    </th>
                    <th
                        className="field_item field_item_title tr_duration"
                        title={strings_statistics.avgTurnover}
                        data-entity="run"
                        data-field="tradeDuration"
                    >
                        Average Turnover
                    </th>
                    <th
                        className="field_item field_item_title max_dd"
                        title={strings_statistics.maximumDrawdown}
                        data-entity="run"
                        data-field="maxDropdown"
                    >
                        Maximum Drawdown
                    </th>
                    <th className="shrink-toggle" />
                </tr>
            );
        }}
    </LanguageConsumer>
);

class Statistics extends React.Component {
    constructor() {
        super();
        this.state = {
            data: [],
        };
    }

    componentDidMount() {
        let headers = {};
        const that = this;
        const access_token = getCookie('access_token');
        if (access_token && access_token != '') {
            headers = {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            };
        }
        fetch('/referee/submission/global?period=3&limit=10', headers)
            .then((response) => response.json())
            .then((data) => {
                data.forEach(
                    (item) => (item.ScoreSharpeRatio = Math.min(item.inSmpl, item.periodScore)),
                );
                data.sort((a, b) => {
                    if (isFinite(b.ScoreSharpeRatio - a.ScoreSharpeRatio)) {
                        return b.ScoreSharpeRatio - a.ScoreSharpeRatio;
                    }
                    return isFinite(a.ScoreSharpeRatio) ? -1 : 1;
                });
                this.setState({data});
            })
            .catch(() => {
                setCookie('access_token', '', 0);
                setCookie('refresh_token', '', 0);
                setCookie('username', '', 0);
                setCookie('color', '', 0);
                fetch('/referee/submission/global?period=3&limit=10')
                    .then((response) => response.json())
                    .then((data) => {
                        data.forEach(
                            (item) =>
                                (item.ScoreSharpeRatio = Math.min(item.inSmpl, item.periodScore)),
                        );
                        data.sort((a, b) => {
                            if (isFinite(b.ScoreSharpeRatio - a.ScoreSharpeRatio)) {
                                return b.ScoreSharpeRatio - a.ScoreSharpeRatio;
                            }
                            return isFinite(a.ScoreSharpeRatio) ? -1 : 1;
                        });
                        that.setState({data});
                    });
            });
    }

    collectStatistics = () => {
        tracker.event('GO_TO_FULL_LEADERBOARD');
    };

    render() {
        return (
            <div className="globalList">
                <h2 className="topTenHeader" style={{textTransform: 'uppercase'}}>
                    <TranslatableTitle language={{}} />
                </h2>
                <TranslatableDescr language={{}} />
                <div className="global_list" id="global_list">
                    <table className="subTableHeader">
                        <tbody>
                            <TranslatableHeader language={{}} />
                            {this.state.data.map((model, key) => (
                                <StatisticsItem model={model} rank={1 + key} key={key} />
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="button-wrapper">
                    <a
                        className="leaderbord_link"
                        href="/leaderboard"
                        onClick={() => this.collectStatistics()}
                    >
                        <TranslatableLink language={{}} />
                    </a>
                </div>
            </div>
        );
    }
}

export default Statistics;
