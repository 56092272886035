import React from 'react';
import {Link} from 'react-router-dom';
import {LanguageConsumer, strings_contacts, strings_notFound} from '../index';
import {getCookie, setCookie} from '../cookies';

const Images = {
    dunce: require('../img/dunce.png'),
};

const TranslatableText = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_notFound.setLanguage(language);
            props.language[language] = strings_notFound;
            return (
                <div className="main_404">
                    <div className="err_wrap">
                        <img title="dunce" className="main_404_img" src={Images.dunce} />
                    </div>
                    <div className="error_descr_wpapper">
                        <p className="error_descr">{strings_notFound.title}</p>
                        <Link className="return_main" to="/">
                            {strings_notFound.link}
                        </Link>
                    </div>
                </div>
            );
        }}
    </LanguageConsumer>
);

class NotFound extends React.Component {
    render() {
        return <TranslatableText language={{}} />;
    }
}

export default NotFound;
