import React from 'react';
import '../../css/style.scss';
import {LanguageConsumer, strings_homepage} from '../../index';

const TranslatableTitle = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_homepage.setLanguage(language);
            return (props.language[language] = strings_homepage.uniquenessTitle);
        }}
    </LanguageConsumer>
);
const TranslatableText = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_homepage.setLanguage(language);
            return (props.language[language] = strings_homepage.uniquenessText);
        }}
    </LanguageConsumer>
);

class Uniqueness extends React.Component {
    render() {
        return (
            <div className="about_container uniqueness">
                <div className="about_wrapper">
                    <pre>
                        <TranslatableTitle language={{}} />
                    </pre>
                    <pre>
                        <TranslatableText language={{}} />
                    </pre>
                </div>
            </div>
        );
    }
}
export default Uniqueness;
