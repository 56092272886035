/* eslint-disable */
let debug = true;

function init(account) {
    console.log("Twitter init.", account);
}

function event(name) {
}

function userInfo(info){
}

function hit(url) {
}

export { init, event, hit, userInfo }
