import React, {Component} from 'react';
import '../css/style.scss';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebook, faLinkedin, faTelegramPlane, faVk} from '@fortawesome/free-brands-svg-icons';
import {faComments} from '@fortawesome/free-regular-svg-icons';
import {LanguageConsumer, strings_menu} from '../index';
import {getCookie} from '../cookies';
import PlatformConfigurator from '../0_configuration/PlatformConfigurator';

const TranslatableContest = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_menu.setLanguage(language);
            return (props.language[language] = strings_menu.contest);
        }}
    </LanguageConsumer>
);
const TranslatableTerms = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_menu.setLanguage(language);
            return (props.language[language] = strings_menu.policyLink_conditions);
        }}
    </LanguageConsumer>
);
const TranslatablePersonalData = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_menu.setLanguage(language);
            return (props.language[language] = strings_menu.policyLink_personaldata);
        }}
    </LanguageConsumer>
);
const TranslatableCopyright = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_menu.setLanguage(language);
            props.language[language] = strings_menu.policy_copyright;
            return (
                <span className="copywrite">
                    {strings_menu.policy_copyright_1} {new Date().getFullYear()}{' '}
                    {strings_menu.policy_copyright_2}
                </span>
            );
        }}
    </LanguageConsumer>
);

const TranslatableCookies = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_menu.setLanguage(language);
            return (props.language[language] = strings_menu.policyLink_cookies);
        }}
    </LanguageConsumer>
);
const TranslatableFaq = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_menu.setLanguage(language);
            return (props.language[language] = strings_menu.faq);
        }}
    </LanguageConsumer>
);
const TranslatableBlog = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_menu.setLanguage(language);
            return (props.language[language] = strings_menu.blog);
        }}
    </LanguageConsumer>
);
const TranslatableCareer = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_menu.setLanguage(language);
            return (props.language[language] = strings_menu.career);
        }}
    </LanguageConsumer>
);
const TranslatableAccount = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_menu.setLanguage(language);
            return (props.language[language] = strings_menu.myAccount);
        }}
    </LanguageConsumer>
);

const TranslatableLearn = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_menu.setLanguage(language);
            return (props.language[language] = strings_menu.learn);
        }}
    </LanguageConsumer>
);

class Footer extends React.Component {
    render() {
        const logoClass = PlatformConfigurator.getLogoClass();
        const language = getCookie('language')
            ? getCookie('language')
            : strings_menu.getInterfaceLanguage();
        const documentationLink = `/documentation/${language.substring(0, 2)}/`;
        return (
            <footer>
                <div className="wrapper">
                    <div className="main_footerWrapper">
                        <div className="main_footer">
                            <nav className="footerMenu">
                                <ul>
                                    <li>
                                        <Link
                                            className="nav-link"
                                            to={documentationLink}
                                            target="_self"
                                        >
                                            <TranslatableLearn language={{}} />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to="/contest" target="_self">
                                            <TranslatableContest language={{}} />
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="main_footer">
                            <nav className="footerMenu">
                                <ul>
                                    <li>
                                        <Link to="/career" className="nav-link">
                                            <TranslatableCareer language={{}} />
                                        </Link>
                                    </li>
                                    <li>
                                        <a
                                            className="nav-link"
                                            rel="noopener noreferrer"
                                            href={`${window.location.origin.toString()}/personalpage/login`}
                                            target="_self"
                                        >
                                            <TranslatableAccount language={{}} />
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="main_footer">
                            <nav className="footerMenu">
                                <ul>
                                    <li>
                                        <a
                                            className="nav-link"
                                            rel="noopener noreferrer"
                                            href="/personalinfo"
                                            target="_self"
                                        >
                                            <TranslatablePersonalData language={{}} />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="nav-link"
                                            rel="noopener noreferrer"
                                            href="/termsofuse"
                                            target="_self"
                                        >
                                            <TranslatableTerms language={{}} />
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="main_footer">
                            <nav className="footerMenu">
                                <ul>
                                    <li>
                                        <a
                                            className="nav-link"
                                            rel="noopener noreferrer"
                                            href="/cookies"
                                            target="_self"
                                        >
                                            <TranslatableCookies language={{}} />
                                        </a>
                                    </li>
                                    <li>
                                        <div className="socButtonWrapper">
                                            <a
                                                rel="noopener noreferrer"
                                                title="VK"
                                                aria-label="VK"
                                                href="https://vk.com/quantnetrussia"
                                                target="_self"
                                                className="VK"
                                            >
                                                <FontAwesomeIcon fixedWidth icon={faVk} size="2x" />
                                            </a>

                                            <a
                                                rel="noopener noreferrer"
                                                title="Telegram"
                                                aria-label="Telegram"
                                                href="https://t.me/quantnetrussia"
                                                target="_self"
                                                className="TG"
                                            >
                                                <FontAwesomeIcon
                                                    fixedWidth
                                                    icon={faTelegramPlane}
                                                    size="2x"
                                                />
                                            </a>

                                            <a
                                                rel="noopener noreferrer"
                                                title="Linkedin"
                                                aria-label="Linkedin"
                                                href="https://www.linkedin.com/company/quantnet-ai"
                                                target="_self"
                                                className="Linkedin"
                                            >
                                                <FontAwesomeIcon
                                                    fixedWidth
                                                    icon={faLinkedin}
                                                    size="2x"
                                                />
                                            </a>

                                            {/* <a */}
                                            {/*    rel="noopener noreferrer" */}
                                            {/*    title="Mattermost forum" */}
                                            {/*    aria-label="Mattermost forum" */}
                                            {/*    href="https://quantnet.ai/community/signup_email?id=kzgfsat1eibzm8rk3rfecpkyqy" */}
                                            {/*    target="_self" */}
                                            {/*    className={'Mattermost'} */}
                                            {/* > */}
                                            {/*    <FontAwesomeIcon fixedWidth={true} icon={ faComments }  size={'2x'}/> */}
                                            {/* </a> */}
                                        </div>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>

                    <div className="copywriteWrapper ">
                        <div className="main_footer">
                            <Link className={`logo ${logoClass}`} to="/">
                                Home
                            </Link>
                        </div>
                        <TranslatableCopyright language={{}} />
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
