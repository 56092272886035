import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClock} from '@fortawesome/free-regular-svg-icons';
import {faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';
import {faTelegram, faVk} from '@fortawesome/free-brands-svg-icons';
import {LanguageConsumer, strings_contacts} from '../../../index';
import ContactForm from './ContactForm';

const TranslatableTitleForm = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_contacts.setLanguage(language);
            return (props.language[language] = strings_contacts.formTitle);
        }}
    </LanguageConsumer>
);
const TranslatableTextForm = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_contacts.setLanguage(language);
            return (props.language[language] = strings_contacts.formText);
        }}
    </LanguageConsumer>
);

const TranslatableContactForm = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_contacts.setLanguage(language);
            props.language[language] = strings_contacts;
            return <ContactForm data={strings_contacts} />;
        }}
    </LanguageConsumer>
);

const TranslatableTitleTeam = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_contacts.setLanguage(language);
            return (props.language[language] = strings_contacts.adressHeader);
        }}
    </LanguageConsumer>
);

const TranslatableAdresInformation = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_contacts.setLanguage(language);

            return (
                <div
                    className="mainInfoWrapper about_wrapper about_wrapper_contacts"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        width: '100%',
                    }}
                >
                    <h6>{strings_contacts.adressHeader} </h6>
                    <p>{strings_contacts.adressText} </p>
                    <pre className="addressInfo">{strings_contacts.email}</pre>
                    <pre className="addressInfo">{strings_contacts.adress}</pre>
                    <pre className="addressInfo">
                        Telegram:{' '}
                        <a href="https://t.me/quantnetrussia" target="_blank">
                            @quantnetrussia
                        </a>{' '}
                    </pre>
                    <pre className="addressInfo">
                        GitHub:{' '}
                        <a href="https://github.com/qntnet" target="_blank">
                            qntnet
                        </a>
                    </pre>
                </div>
            );
        }}
    </LanguageConsumer>
);

const TranslatableMainInformation = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_contacts.setLanguage(language);

            return (
                <div
                    className="mainInfoWrapper about_wrapper"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        width: 'fit-content',
                        padding: '0',
                        margin: '0',
                    }}
                ></div>
            );
        }}
    </LanguageConsumer>
);
const TranslatableTitle = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_contacts.setLanguage(language);
            return (props.language[language] = strings_contacts.mainTitle);
        }}
    </LanguageConsumer>
);
class AdditionalInfo extends React.Component {
    render() {
        return (
            <div>
                <div className="about_container contactForm">
                    <div className="teamPhoto">
                        <TranslatableAdresInformation language={{}} />
                    </div>
                    <div className="about_wrapper">
                        <TranslatableMainInformation language={{}} />
                        <TranslatableContactForm language={{}} />
                    </div>
                </div>
            </div>
        );
    }
}

export default AdditionalInfo;
