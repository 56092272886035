import {getCookie} from '../cookies';

export default function getMoneyFormat(money, userLang = '') {
    if (userLang !== '') {
        const formatter = new Intl.NumberFormat(userLang);
        return formatter.format(money);
    }
    let lang = 'en';
    if (getCookie('language') === 'ru') {
        lang = 'ru';
    }
    const formatter = new Intl.NumberFormat(lang);
    return formatter.format(money);
}
