import React from 'react';
import * as PropTypes from 'prop-types';

import {LanguageConsumer, strings_statistics_users} from '../../index';
import getMoneyFormat from '../MoneyFormatHelper';
import PlatformConfigurator from '../../0_configuration/PlatformConfigurator';

class TopRankUsersAboutPrize extends React.Component {
    render() {
        return (
            <LanguageConsumer>
                {({language}) => {
                    strings_statistics_users.setLanguage(language);
                    return this.renderLanguage(strings_statistics_users);
                }}
            </LanguageConsumer>
        );
    }

    renderLanguage(messages) {
        const potentialProfit = this.props.totalPrizeFondActive;
        const totalPrizeFond = this.props.totalPrizeFondPaid;

        let potentialProfitForView = '';
        if (potentialProfit) {
            potentialProfitForView = getMoneyFormat(potentialProfit.toFixed(0));
        }
        let totalPrizeFondForView = '';
        if (totalPrizeFond) {
            totalPrizeFondForView = getMoneyFormat(totalPrizeFond.toFixed(0));
        }
        const currency = PlatformConfigurator.getCurrency();

        return (
            <div className="topRankUsersAboutPrize">
                <div className="topRankUsersAboutPrizePotential">
                    {messages.prize_active_competitions} <strong>0</strong>
                    &nbsp;{currency}.
                </div>
                <span className="topRankUsersAboutSeparator"></span>
                <div className="topRankUsersAboutPrizePaid">
                    {messages.paid_prizes} <strong>{totalPrizeFondForView}</strong>&nbsp;{currency}.
                </div>
            </div>
        );
    }
}

TopRankUsersAboutPrize.propTypes = {
    totalPrizeFondActive: PropTypes.number,
    totalPrizeFondPaid: PropTypes.number,
};

export default TopRankUsersAboutPrize;
