import React, {Suspense} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {utm} from 'url-utm-params';
import Header from '../header/Header';
import LanguageProvider from '../languageProvider/LanguageProvider';
import Homepage from '../homepage/Homepage';
import Footer from '../header/Footer';

import CookiesPOPUP from '../cookies/CookiesPOPUP';

import NotFound from '../404/NotFound';

import * as tracker from '../tracker';
import {getCookie} from '../cookies';

const TermsOfUse = React.lazy(() => import('../termsofuse/TermsOfUse'));
const PersonalData = React.lazy(() => import('../personaldata/PersonalData'));
const Vacancy = React.lazy(() => import('../career/vacancy/Vacancy'));
const OpenPositions = React.lazy(() => import('../career/openpositions/OpenPositions'));
const FAQ = React.lazy(() => import('../faq/FAQ'));
const Contacts = React.lazy(() => import('../contacts/Contacts'));

const Contest = React.lazy(() => import('../competition/Contest'));
const FirstBitcoinContest = React.lazy(() =>
    import('../events/firstBitcoinContest/FirstBitcoinContest'),
);

const Events = React.lazy(() => import('../events/Events'));
const Leaderboard = React.lazy(() => import('../leaderboard/Leaderboard'));

const StatisticSubmissionPage = React.lazy(() => import('../statisticSubmission'));
const Cookies = React.lazy(() => import('../cookies/Cookies'));

class App extends React.Component {
    componentDidMount() {
        const utmName = 'utm_is_exist';
        if (!isUtmExist(utmName)) {
            setUtmInCookie(utmName);
        }
        const qn_uid = getCookie('qn_uid');
        tracker.userInfo({qntId: qn_uid});

        function isUtmExist(utmName) {
            const currentUtm = getCookie(utmName);
            return (
                currentUtm !== '' &&
                currentUtm !== undefined &&
                currentUtm !== null &&
                currentUtm !== 'undefined' &&
                Object.keys(currentUtm).length !== 0
            );
        }

        function setUtmInCookie(utmName) {
            const metrics = utm(window.location.href, '&');
            for (const [key, value] of Object.entries(metrics)) {
                const item = `${key}=${value}`;
                document.cookie = item;
            }
            document.cookie = `${utmName}=exist`;
        }
    }

    render() {
        return (
            <Router>
                <div className="wrapper">
                    <LanguageProvider>
                        <div>
                            <Header />
                        </div>
                        <main className="mainWrapper">
                            <Suspense fallback={<div>Loading</div>}>
                                <Switch>
                                    <Route exact path="/" component={Homepage} />
                                    <Route exact path="/leaderboard" component={Leaderboard} />
                                    <Route exact path="/career" component={OpenPositions} />
                                    <Route
                                        exact
                                        path="/career/openpositions"
                                        component={OpenPositions}
                                    />
                                    <Route
                                        exact
                                        path="/career/openpositions/:id"
                                        component={Vacancy}
                                    />
                                    <Route exact path="/termsofuse" component={TermsOfUse} />
                                    <Route exact path="/personalinfo" component={PersonalData} />
                                    <Route exact path="/cookies" component={Cookies} />
                                    <Route exact path="/events" component={Events} />
                                    <Route
                                        exact
                                        path="/events/first_bitcoin_contest"
                                        component={FirstBitcoinContest}
                                    />
                                    <Route exact path="/contest" component={Contest} />
                                    <Route
                                        exact
                                        path="/statistic/:submissionId"
                                        component={StatisticSubmissionPage}
                                    />
                                    <Route exact path="/faq" component={FAQ} />
                                    <Route exact path="/contacts" component={Contacts} />
                                    <Route
                                        exact
                                        path="/controlpanel"
                                        component={() => {
                                            window.location = `${window.location.origin.toString()}/controlpanel/`;
                                            return null;
                                        }}
                                    />
                                    <Route
                                        exact
                                        path="/personalpage"
                                        component={() => {
                                            window.location = `${window.location.origin.toString()}/personalpage/`;
                                            return null;
                                        }}
                                    />
                                    <Route
                                        exact
                                        path="/support"
                                        component={() => {
                                            window.location = 'https://quantnet.ai/community';
                                            return null;
                                        }}
                                    />
                                    <Route component={NotFound} />
                                </Switch>
                            </Suspense>
                            <CookiesPOPUP />
                        </main>
                        <div style={{background: '#020202'}}>
                            <Footer />
                        </div>
                    </LanguageProvider>
                </div>
            </Router>
        );
    }
}

export default App;
