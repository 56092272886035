import React from 'react';
import '../css/style.scss';
import {LanguageConsumer, strings_cookies} from '../index';

const TranslatableMainText = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_cookies.setLanguage(language);
            props.language[language] = strings_cookies;

            return (
                <>
                    <p>{strings_cookies.cookiesPopup_text}</p>
                    <p>
                        <a href="/cookies"> {strings_cookies.cookiesPopup_link}</a>
                    </p>
                </>
            );
        }}
    </LanguageConsumer>
);
const TranslatabLable = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_cookies.setLanguage(language);
            props.language[language] = strings_cookies;

            return strings_cookies.cookiesPopup_agree;
        }}
    </LanguageConsumer>
);

class Cookies extends React.Component {
    constructor() {
        super();
        this.state = {
            CookieControl: true,
            isAgreementChecked: false,
        };
    }

    componentDidMount() {
        this.checkCookie();
    }

    checkCookie() {
        const CookieControl = this.getCookie('CookieControl');
        if (CookieControl) {
            this.setState({
                CookieControl: true,
            });
        } else {
            this.setState({
                CookieControl: false,
            });
        }
    }

    setCookie(cname, cvalue) {
        document.cookie = `${cname}=${cvalue};path=/`;
    }

    getCookie(cname) {
        const name = `${cname}=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    clickCheckbox = () => {
        const old_isAgreementChecked = this.state.isAgreementChecked;
        const isAgreementChecked = !old_isAgreementChecked;
        this.setState({
            isAgreementChecked,
        });
    };

    handleChange = () => {
        const CookieControl = this.state.isAgreementChecked;
        this.setCookie('CookieControl', CookieControl);
        this.setState({
            CookieControl,
        });
    };

    render() {
        return (
            <div
                className={
                    this.state.CookieControl
                        ? 'cookieControl cookieControl_hide'
                        : 'cookieControl cookieControl_show'
                }
            >
                <section>
                    <TranslatableMainText language={{}} />
                    <label className="checkbox_wrapper">
                        <input
                            className="checkbox"
                            type="checkbox"
                            name="future"
                            checked={this.state.isAgreementChecked}
                            onChange={this.handleChange}
                            id="future"
                        />
                        <span
                            style={{background: 'white'}}
                            className="checkbox-custom trigger_filter"
                            id="future_check"
                            onClick={this.clickCheckbox}
                        />
                        <span className="label" onClick={this.clickCheckbox}>
                            <TranslatabLable language={{}} />
                        </span>
                    </label>
                </section>
            </div>
        );
    }
}

export default Cookies;
