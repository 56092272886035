import React from 'react';
import '../../css/style.scss';
import {LanguageConsumer, strings_homepage} from '../../index';

class AboutLibrary extends React.Component {
    render() {
        return (
            <LanguageConsumer>
                {({language}) => {
                    strings_homepage.setLanguage(language);
                    if (language === 'ru') {
                        return this.renderRu();
                    }

                    return this.renderEn();
                }}
            </LanguageConsumer>
        );
    }

    renderRu() {
        return (
            <div className="about_container uniqueness">
                <div className="about_wrapper">
                    <h2 className="subHeader">ЭКОСИСТЕМА</h2>

                    <p>
                        Вам доступны инфраструктура (Jupyter и JupyterLab),{' '}
                        <a href="https://github.com/qntnet">open-source библиотеки</a> для
                        тестирования алгоритмов, готовые примеры стратегий.
                    </p>
                    <p>
                        Библиотеки можно использовать для частной торговли, выгрузки фундаментальных
                        и рыночных данных о компаниях, которые торгуются на NASDAQ и Нью-Йоркской
                        фондовой биржи.
                    </p>
                    <p>
                        <a className="blogLink" href="/documentation/ru/home_quick_start.html">
                            Узнать об этом больше
                        </a>
                    </p>
                </div>
            </div>
        );
    }

    renderEn() {
        return (
            <div className="about_container uniqueness">
                <div className="about_wrapper">
                    <h2 className="subHeader">ECOSYSTEM</h2>

                    <p>
                        Infrastructure (Jupyter and JupyterLab),{' '}
                        <a href="https://github.com/qntnet">open-source libraries</a> or testing
                        algorithms, ready-made examples of strategies are available to you.
                    </p>
                    <p>
                        Libraries can be used for private trading, uploading fundamental and market
                        data about companies that are listed on the NASDAQ and the New York Stock
                        Exchange.
                    </p>
                    <p>
                        <a className="blogLink" href="/documentation/en/">
                            Read more about it
                        </a>
                    </p>
                </div>
            </div>
        );
    }
}

export default AboutLibrary;
