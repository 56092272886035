import React from 'react';
import '../../css/style.scss';
import {Link} from 'react-router-dom';
import {LanguageConsumer, strings_homepage, strings_menu} from '../../index';

const Images = {
    arrow: require('../../img/arrow.svg'),
    learn: require('../../img/learn.svg'),
    prize: require('../../img/prize.svg'),
    strategies: require('../../img/strategies.svg'),
};

const TranslatableLinkLearn = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_homepage.setLanguage(language);
            return (
                <Link className="link_button" target="_self" to="/learn">
                    {strings_homepage.offeringLink_learn}
                </Link>
            );
        }}
    </LanguageConsumer>
);

const TranslatableTitle = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_homepage.setLanguage(language);
            return (props.language[language] = strings_homepage.offeringTitle);
        }}
    </LanguageConsumer>
);
const TranslatableTitleLearn = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_homepage.setLanguage(language);
            return (props.language[language] = strings_homepage.offeringTitle_learn);
        }}
    </LanguageConsumer>
);
const TranslatableTextLearn = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_homepage.setLanguage(language);
            return (props.language[language] = strings_homepage.offeringText_learn);
        }}
    </LanguageConsumer>
);

const TranslatableTitleSubmit = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_homepage.setLanguage(language);
            return (props.language[language] = strings_homepage.offeringTitle_submit);
        }}
    </LanguageConsumer>
);
const TranslatableTextSubmit = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_homepage.setLanguage(language);
            return (props.language[language] = strings_homepage.offeringText_submit);
        }}
    </LanguageConsumer>
);
const TranslatableLinkSubmit = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_homepage.setLanguage(language);
            return (props.language[language] = strings_homepage.offeringLink_submit);
        }}
    </LanguageConsumer>
);

const TranslatableTitlePrize = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_homepage.setLanguage(language);
            return (props.language[language] = strings_homepage.offeringTitle_prize);
        }}
    </LanguageConsumer>
);
const TranslatableTextPrize = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_homepage.setLanguage(language);
            return (props.language[language] = strings_homepage.offeringText_prize);
        }}
    </LanguageConsumer>
);
const TranslatableLinkPrize = (props) => (
    <LanguageConsumer>
        {({language}) => {
            strings_homepage.setLanguage(language);
            return (props.language[language] = strings_homepage.offeringLink_prize);
        }}
    </LanguageConsumer>
);

class Proposals extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="thurd_line">
                <div className="body_offer">
                    <h2 className="section_header">
                        <TranslatableTitle language={{}} />
                    </h2>
                    <div className="offer_container">
                        <div className="offer_item learn">
                            <div className="offer_p_wrapper">
                                <img src={Images.learn} />
                                <h3>
                                    <TranslatableTitleLearn language={{}} />
                                </h3>
                                <pre className="offer_p">
                                    <TranslatableTextLearn language={{}} />
                                </pre>
                            </div>
                        </div>
                        <div className="offer_item arrow">
                            <img src={Images.arrow} />
                        </div>
                        <div className="offer_item strategies">
                            <div className="offer_p_wrapper">
                                <img src={Images.strategies} />
                                <h3>
                                    <TranslatableTitleSubmit language={{}} />
                                </h3>
                                <p className="offer_p">
                                    <TranslatableTextSubmit language={{}} />
                                </p>
                            </div>
                        </div>
                        <div className="offer_item arrow">
                            <img src={Images.arrow} />
                        </div>
                        <div className="offer_item prize">
                            <div className="offer_p_wrapper">
                                <img src={Images.prize} />
                                <h3>
                                    <TranslatableTitlePrize language={{}} />
                                </h3>
                                <pre className="offer_p">
                                    <TranslatableTextPrize language={{}} />
                                </pre>
                            </div>
                        </div>
                    </div>
                    <div className="home_video_quick_start">
                        <iframe
                            width="100%"
                            height="615"
                            src="https://www.youtube.com/embed/XUHDOuKPhIE"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            </div>
        );
    }
}

export default Proposals;
