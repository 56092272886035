import React from 'react';

import * as PropTypes from 'prop-types';
import CompetitionAllItem from './CompetitionAllItem';

import getMoneyFormat from '../MoneyFormatHelper';
import PlatformConfigurator from '../../0_configuration/PlatformConfigurator';

function getSortByColumn(competitions, colIndex, reverse) {
    const competitionsSorted = [...competitions];
    if (reverse === true) {
        competitionsSorted.sort(sortDates).reverse();
    } else {
        competitionsSorted.sort(sortDates);
    }

    return competitionsSorted;

    function sortDates(a, b) {
        return new Date(b[colIndex]) - new Date(a[colIndex]);
    }
}

class CompetitionAll extends React.Component {
    state = {
        competitionsSorted: [],
        sortedColumn: 'finish',
        sortedAscending: false,
        monthYearFond: {},
    };

    componentWillReceiveProps(newProps) {
        if (newProps.competitions.length > 0) {
            const competitionsSorted = getSortByColumn(
                newProps.competitions,
                'finish',
                newProps.isDirectionSortDown,
            );

            this.setState({
                competitionsSorted,
                sortedAscending: newProps.isDirectionSortDown,
                monthYearFond: getMonthYearFond(competitionsSorted),
            });
        }

        function getMonthYearFond(competitionAll) {
            const result = {};
            for (const {prize, finishMonthYear} of competitionAll) {
                if (result[finishMonthYear]) {
                    result[finishMonthYear] += prize;
                } else {
                    result[finishMonthYear] = prize;
                }
            }
            return result;
        }
    }

    changeSortAscending() {
        let competitionsSortedNew = [];
        const sortedAscending = !this.state.sortedAscending;
        competitionsSortedNew = getSortByColumn(
            this.props.competitions,
            this.state.sortedColumn,
            sortedAscending,
        );
        this.setState(
            {
                sortedAscending,
                competitionsSorted: [],
            },
            () => {
                this.setState({
                    competitionsSorted: competitionsSortedNew,
                });
            },
        );
    }

    render() {
        const isEmptyClass = this.props.competitions.length === 0 ? 'empty' : '';

        let monthForGrouping = '';
        const {monthYearFond} = this.state;

        const {messages} = this.props;

        return (
            <div className={`competitions_results_competition_all ${isEmptyClass}`}>
                <table>
                    <thead>
                        <tr>
                            <th className="th_prize_th">{messages.prize}</th>
                            <th className="th_duration">{messages.duration}</th>
                            <th className="th_start">{messages.start}</th>

                            <th className="th_finish" onClick={() => this.changeSortAscending()}>
                                {messages.finish}
                            </th>

                            <th className="th_type">{messages.type}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.competitionsSorted.map((competition, key) => {
                            const isOneGroup = monthForGrouping === competition.finishMonthYear;
                            if (isOneGroup) {
                                return (
                                    <CompetitionAllItem
                                        competition={competition}
                                        key={competition.id}
                                    />
                                );
                            }
                            monthForGrouping = competition.finishMonthYear;
                            const fond = monthYearFond[competition.finishMonthYear];

                            return (
                                <React.Fragment key={competition.finishMonthYear + competition.id}>
                                    <tr
                                        className="tr_group_month_year"
                                        key={competition.finishMonthYear}
                                    >
                                        <td colSpan={5}>
                                            {monthForGrouping} - {messages.prize}{' '}
                                            <span>{getMoneyFormat(fond.toFixed(0))}</span>{' '}
                                            {PlatformConfigurator.getCurrency()}
                                        </td>
                                    </tr>

                                    <CompetitionAllItem
                                        competition={competition}
                                        key={competition.id}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

CompetitionAll.propTypes = {
    competitions: PropTypes.array,
    isDirectionSortDown: PropTypes.bool,
    messages: PropTypes.object,
};

export default CompetitionAll;
